<template>
  <OnboardingStep
    :title="$t('onboarding.magicGoalsSetupView.title')"
    :next-text="$t('onboarding.magicGoalsSetupView.nextText')"
    v-bind="$props"
  >
    <MagicGoalView v-bind="$props" />
  </OnboardingStep>
</template>

<script>
import { BaseStepView } from "./BaseStepView";
import MagicGoalView from "../Goal/SetupView";

export default {
  name: "MagicGoalsSetupView",
  components: { MagicGoalView },
  extends: BaseStepView,
};
</script>
